<template>
  <div>
    <div v-if="hasTitle" class="text-xl text-gray3 mb-6">
      {{ $t('mobileAppsTitle') }}
    </div>
    <div class="flex" :class="isSmall ? 'gap-5' : 'gap-6'">
      <NuxtLink :to="MobileAppUrl.AppleStore" title="App Store" target="_blank" class="inline-block">
        <img
          class="w-full h-auto object-cover"
          :class="isSmall && 'max-h-[42px]'"
          :src="locale === 'sl' ? appStoreSlo : appStore"
          :width="isSmall ? 142 : 194"
          :height="isSmall ? 44 : 56"
          alt="App store"
        />
      </NuxtLink>

      <NuxtLink
        :to="MobileAppUrl.GooglePlay"
        title="Google play"
        target="_blank"
      >
        <img
          class="w-full h-auto object-cover"
          :class="isSmall && 'max-h-[42px]'"
          :src="gpSource"
          :width="isSmall ? 142 : 194"
          :height="isSmall ? 44 : 56"
          alt="Google play"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import googlePlay from '~/assets/images/ui/google-play.svg'
import googlePlaySlo from '~/assets/images/ui/google-play-slo.svg'
import googlePlayCro from '~/assets/images/ui/google-play-cro.svg'
import appStore from '~/assets/images/ui/app-store.svg'
import appStoreSlo from '~/assets/images/ui/app-store-slo.svg'
import { MobileAppUrl } from '~/types'

const { locale } = useI18n()

const gpSource = computed(() => {
  let source
  switch (locale.value) {
    case 'sl':
      source = googlePlaySlo
      break
    case 'hr':
      source = googlePlayCro
      break
    default:
      source = googlePlay
  }
  return source
})

defineProps({
  isSmall: {
    type: Boolean,
    default: false
  },
  hasTitle: {
    type: Boolean,
    default: false
  }
})
</script>
